<template>
  <div class="task-view">
    <v-container fluid>
      <v-fab-transition>
        <v-btn
          fab
          small
          absolute
          top
          left
          icon
          :style="{ top: '-5px', left: '0px', transform: pinTaskFilter ? 'rotate(180deg)' : 'unset' }"
          @click="toggleTaskFilterPin"
        >
          <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
        </v-btn>
      </v-fab-transition>
      <v-row>
        <v-col
          v-show="pinTaskFilter"
          cols="2"
        >
          <v-list>
            <v-subheader>Aufgaben Filter</v-subheader>
            <v-progress-linear
              v-show="loadQuantities"
              style="margin-bottom: -4px;"
              indeterminate
            ></v-progress-linear>
            <v-list-item-group
              v-model="selectedTaskDefinitionsList"
              multiple
              color="primary"
              @change="taskDefinitionSelectedList"
            >
              <v-list-item
                v-for="(item, i) in taskQuantities"
                :key="i"
              >
                <v-list-item-content>
                  <v-list-item-title class="task-definitions">
                    <span>{{ item.taskName }}</span>
                    <p class="text--secondary mb-0">
                      {{ item.count }}
                      <span v-if="item.countFollowUpPassed > 0">
                        | <span class="orange--text accent-4"> Follow Up {{ item.countFollowUpPassed }}</span>
                      </span>
                    </p>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col>
          <v-expansion-panels
            ref="filterExpansionPanel"
            class="mb-3"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <template v-slot:default="{ open }">
                  <v-row no-gutters>
                    <v-col cols="1">
                      Filter
                    </v-col>
                    <v-col
                      cols="11"
                      class="text--secondary"
                    >
                      <v-fade-transition>
                        <v-row
                          v-if="!open"
                          no-gutters
                          style="width: 100%"
                          class="active-filter"
                        >
                          <v-col
                            v-if="taskDefinitionKeys.length > 0"
                            cols="4"
                          >
                            <template v-if="taskDefinitionKeys.length === 1">
                              Aufgabe: {{ taskDefinitionKeys[0].text }}
                            </template>
                            <template v-else>
                              {{ taskDefinitionKeys.length }} Aufgaben
                            </template>
                          </v-col>
                          <v-col
                            v-if="searchParams.customerId"
                            cols="4"
                          >
                            Kundennummer: {{ searchParams.customerId }}
                          </v-col>
                          <v-col
                            v-if="searchParams.contractId"
                            cols="4"
                          >
                            Vertragsnummer: {{ searchParams.contractId }}
                          </v-col>
                          <v-col
                            v-if="projectLookup"
                            cols="4"
                          >
                            Projekt: {{ projectLookup }}
                          </v-col>
                          <v-col
                            v-if="searchParams.firstName"
                            cols="4"
                          >
                            Vorname: {{ searchParams.firstName }}
                          </v-col>
                          <v-col
                            v-if="searchParams.lastName"
                            cols="4"
                          >
                            Nachname: {{ searchParams.lastName }}
                          </v-col>
                          <v-col
                            v-if="searchParams.postalCode"
                            cols="4"
                          >
                            PLZ: {{ searchParams.postalCode }}
                          </v-col>
                          <v-col
                            v-if="searchParams.city"
                            cols="4"
                          >
                            Stadt: {{ searchParams.city }}
                          </v-col>
                          <v-col
                            v-if="searchParams.street"
                            cols="4"
                          >
                            Straße: {{ searchParams.street }}
                          </v-col>
                          <v-col
                            v-if="searchParams.houseNumber"
                            cols="4"
                          >
                            Hausnummer: {{ searchParams.houseNumber }}
                          </v-col>
                          <v-col
                            v-if="searchParams.unassigned"
                            cols="4"
                          >
                            <v-icon small>
                              {{ icons.mdiCheckboxMarkedOutline }}
                            </v-icon>
                            Nicht zugewiesen
                          </v-col>
                          <v-col
                            v-if="searchParams.assignedToMe"
                            cols="4"
                          >
                            <v-icon small>
                              {{ icons.mdiCheckboxMarkedOutline }}
                            </v-icon>
                            Eigene Aufgaben
                          </v-col>
                          <v-col
                            v-if="searchParams.elapsedFollowUpDate"
                            cols="4"
                          >
                            <v-icon small>
                              {{ icons.mdiCheckboxMarkedOutline }}
                            </v-icon>
                            Nachbearbeitungsdatum überschritten
                          </v-col>
                        </v-row>
                      </v-fade-transition>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:actions>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click.stop="updatePinFilter(!pinFilter)"
                      >
                        <v-icon style="transform: unset !important;">
                          <template v-if="pinFilter">
                            {{ icons.mdiPinOutline }}
                          </template>
                          <template v-else>
                            {{ icons.mdiPinOffOutline }}
                          </template>
                        </v-icon>
                      </v-btn>
                    </template>
                    <span v-if="pinFilter">Filter ausblenden bei Seitenaufruf</span>
                    <span v-else>Filter einblenden bei Seitenaufruf</span>
                  </v-tooltip>
                  <v-icon>
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="9">
                    <v-combobox
                      v-model="taskDefinitionKeys"
                      :items="taskDefinitionItems"
                      label="Aufgaben Filtern"
                      dense
                      clearable
                      multiple
                      outlined
                      small-chips
                      value="value"
                      @change="taskDefinitionSelectedCombo"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="searchParams.unassigned"
                      label="Nicht zugewiesen"
                      @change="searchParams.assignedToMe = false"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.customerId"
                      label="Kundennummer"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.contractId"
                      label="Vertragsnummer"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="projectLookup"
                      label="Projekt"
                      hint="Name oder Nummer"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="searchParams.assignedToMe"
                      label="Eigene Aufgaben"
                      @change="searchParams.unassigned = false"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.firstName"
                      label="Vorname"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.lastName"
                      label="Nachname"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.postalCode"
                      label="PLZ"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-switch
                      v-model="searchParams.elapsedFollowUpDate"
                      label="Nachbearbeitungsdatum überschritten"
                    ></v-switch>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.city"
                      label="Stadt"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.street"
                      label="Straße"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="searchParams.houseNumber"
                      label="Hausnummer"
                      clearable
                      dense
                      @keydown.enter="fetchTasks"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="warning"
                      class="float-left"
                      :disabled="taskTableLoading"
                      @click="clearSearch"
                    >
                      <v-icon>{{ icons.mdiFilterOffOutline }}</v-icon> Clear
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="float-right"
                      :disabled="taskTableLoading"
                      @click="fetchTasks(null, true)"
                    >
                      <v-icon>{{ icons.mdiFilterCogOutline }}</v-icon> Filtern
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-data-table
            item-key="taskDto.id"
            class="task-table"
            :items="tasks"
            :headers="taskTableHeaders"
            :server-items-length="totalTaskCount"
            :loading="taskTableLoading"
            :page.sync="tablePage"
            :items-per-page="tableItemsPerPage"
            :sort-by.sync="tableSortBy"
            :sort-desc.sync="tableSortDesc"
            hide-default-footer
          >
            <template v-slot:[`item.taskDto.name`]="{item}">
              <p
                style="margin-bottom: unset; cursor: pointer;"
                @click.exact="openTask(item, false)"
                @click.middle.exact="openTask(item, true)"
                @click.ctrl="openTask(item, true)"
              >
                {{ item.taskDto.name }}
              </p>
            </template>
            <template v-slot:[`item.taskDto.created`]="{item}">
              <v-tooltip
                top
                open-delay="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <span
                    v-bind="attrs"
                    v-on="on"
                  >{{ getFormattedDuration(item.taskDto.created) }}</span>
                </template>
                <span>{{ formatDate(item.taskDto.created) }}</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.taskDto.followUp`]="{item}">
              <span :style="followUpStyle(item.taskDto.followUp)">
                <v-icon
                  v-if="item.taskDto.followUp"
                  class="ml-2"
                  size="16"
                >
                  mdi-calendar-arrow-right
                </v-icon>
                {{ formatDateShort(item.taskDto.followUp) }}
              </span>
            </template>
            <template v-slot:[`item.project`]="{item}">
              {{ projectText(item.cacheData) }}
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-btn
                title="Link kopieren"
                icon
                small
                color="primary"
                class="mr-2"
                @click="copyTaskLink(item.taskDto.id)"
              >
                <v-icon>{{ icons.mdiLinkVariant }}</v-icon>
              </v-btn>
              <!--
              <v-btn
                title="Öffnen"
                icon
                small
                color="primary"
                class="mr-2"
                @click="openTask(item, false)"
              >
                <v-icon>{{ icons.mdiOpenInApp }}</v-icon>
              </v-btn>
              -->
              <v-btn
                title="In neuem Tab öffnen"
                icon
                small
                color="primary"
                @click="openTask(item, true)"
              >
                <v-icon>{{ icons.mdiOpenInNew }}</v-icon>
              </v-btn>
            </template>
            <template v-slot:[`footer`]>
              <div class="text-center">
                <div class="v-data-footer pa-2 d-flex justify-space-between">
                  <v-btn
                    color="primary"
                    text
                    :disabled="taskTableLoading"
                    @click.prevent="fetchTasks"
                  >
                    <v-icon class="mr-1">
                      {{ icons.mdiTableRefresh }}
                    </v-icon> Tabelle aktualisieren
                  </v-btn>
                  <v-pagination
                    v-model="tablePage"
                    :disabled="taskTableLoading"
                    :length="pagesLength"
                    :total-visible="9"
                    @input="fetchTasks"
                  ></v-pagination>
                  <div class="d-flex justify-end align-center">
                    <div v-if="totalTaskCount > 0">
                      {{ paginationFrom }} - {{ paginationTo }} von {{ totalTaskCount }}
                    </div>
                    <div
                      class="v-data-footer__select"
                      style="margin-left: unset;"
                    >
                      <v-select
                        :value.sync="tableItemsPerPage"
                        :items="tableItemsPerPageOptions"
                        :disabled="taskTableLoading"
                        label="Aufgaben anzeigen"
                        style="min-width: 105px"
                        hideDetails
                        @input="updateItemsPerPage(tableItemsPerPage, $event)"
                      ></v-select>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </v-data-table>
          <task-dialog
            v-if="showTask"
            :task="showTask"
            :currentIndex="pagedIndex"
            :totalItems="totalTaskCount"
            @closeTask="showTask = null"
            @updateTaskData="updateSelectedTask"
            @refreshTaskData="refreshSelectedTask"
            @loadTaskIndex="loadTaskIndex"
          ></task-dialog>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import NotificationObject from '@/main/NotificationObject.js';
import TimeUtility from '@/util/TimeUtility.js';
import axios from 'axios';
import _ from 'lodash';
import TaskDialog from '@/task/TaskDialog.vue';
import {
  mdiOpenInApp,
  mdiOpenInNew,
  mdiFilterCogOutline,
  mdiFilterOffOutline,
  mdiTableRefresh,
  mdiLinkVariant,
  mdiCheckboxMarkedOutline,
  mdiPinOutline,
  mdiPinOffOutline,
  mdiChevronLeft
} from '@mdi/js';

const SETTING_KEY = 'taskList';

export default {
  name: 'TaskList',
  components: {
    TaskDialog
  },
  data: () => ({
    icons: {
      mdiOpenInApp,
      mdiOpenInNew,
      mdiFilterCogOutline,
      mdiFilterOffOutline,
      mdiTableRefresh,
      mdiLinkVariant,
      mdiCheckboxMarkedOutline,
      mdiPinOutline,
      mdiPinOffOutline,
      mdiChevronLeft
    },
    taskQuantities: [],
    loadQuantities: false,
    tasks: [],
    showTask: null,
    totalTaskCount: -1,
    taskTableLoading: false,
    defaultTaskTableHeaders: [
      {
        text: 'Aufgabe',
        value: 'taskDto.name'
      },
      {
        text: 'Erstellungsdatum',
        value: 'taskDto.created'
      },
      {
        text: 'Follow Up',
        value: 'taskDto.followUp'
      },
      {
        text: 'Zugewiesen',
        value: 'taskDto.assignee'
      },
      {
        text: 'Kundennummer',
        value: 'cacheData.customerId'
      },
      {
        text: 'Vertragsnummer',
        value: 'cacheData.contractId'
      },
      {
        text: 'Projekt',
        value: 'project'
      },
      {
        text: '',
        value: 'actions',
        width: '96px'
      }
    ],
    tableItemsPerPageOptions: [10, 25, 50, 100],
    tablePage: 1,
    tableItemsPerPage: 25,
    tableSortBy: ['taskDto.created'],
    tableSortDesc: [true],
    abortController: null,
    disableLocalStorage: false,
    taskDefinitionKeys: [],
    projectLookup: null,
    searchParams: {
      businessKey: null,
      unassigned: false,
      assignedToMe: false,
      elapsedFollowUpDate: false,
      contractId: null,
      customerId: null,
      projectId: null,
      projectName: null,
      firstName: null,
      lastName: null,
      postalCode: null,
      city: null,
      street: null,
      houseNumber: null
    },
    selectedTaskDefinitionsList: [],
    debounceTask: null,
    pageLoad: false,
    pinFilter: false,
    pinTaskFilter: true
  }),
  computed: {
    taskTableHeaders() {
      const headers = [...this.defaultTaskTableHeaders].map((h) => ({
        ...h,
        sortable: ['taskDto.created', 'taskDto.followUp'].includes(h.value)
      }));
      return headers;
    },
    pagesLength() {
      return Math.ceil(
        Math.max(0, this.totalTaskCount) / this.tableItemsPerPage
      );
    },
    taskDefinitionItems() {
      return (this.taskQuantities ?? []).map((q) => ({
        text: `${q.taskName} (${q.count})`,
        value: q.taskDefinitionKey
      }));
    },
    debounceLoadTask() {
      return _.debounce(function () {
        this.fetchTasks(null, true);
      }, 1000);
    },
    paginationFrom() {
      return (this.tablePage - 1) * this.tableItemsPerPage + 1;
    },
    paginationTo() {
      return Math.min(
        this.totalTaskCount,
        this.tablePage * this.tableItemsPerPage
      );
    },
    currentIndex() {
      return this.tasks.findIndex(
        (t) => t?.taskDto?.id === this?.showTask?.taskDto?.id
      );
    },
    pagedIndex() {
      return this.paginationFrom - 1 + this.currentIndex;
    }
  },
  watch: {
    showTask: function () {
      this.updateUrl();
    },
    tableSortBy: function () {
      this.fetchTasks();
    },
    tableSortDesc: function () {
      this.fetchTasks();
    },
    tableItemsPerPage: function (newVal, oldVal) {
      if (this.totalTaskCount <= oldVal) return;
      if (newVal > oldVal) this.fetchTasks();
      else {
        this.tasks = [...this.tasks.slice(0, newVal)];
      }
    },
    projectLookup: function (newVal) {
      if (this.isNumeric(newVal)) {
        this.searchParams.projectId = newVal;
        this.searchParams.projectName = null;
      } else {
        this.searchParams.projectId = null;
        this.searchParams.projectName = newVal;
      }
    }
  },
  mounted() {
    document.title = `Aufgabenliste  | ${
      process.env.VUE_APP_TITLE ?? 'Goetel SPOT'
    }`;

    // show or hide filter inputs on page load
    this.$refs.filterExpansionPanel.updateInternalValue(
      this.pinFilter ? 0 : -1
    );
  },
  created: function () {
    this.pageLoad = true;

    const settings = this.$settings.get(SETTING_KEY, {});
    if (settings) {
      if (settings.itemsPerPage) {
        this.tableItemsPerPage = settings.itemsPerPage;
      }
      if (settings.pinFilter) {
        this.pinFilter = !!settings.pinFilter;
      }
      if (settings.pinTaskFilter) {
        this.pinTaskFilter = !!settings.pinTaskFilter;
      }
    }

    const urlParams = this.parseURLSearchParams();
    if (urlParams === undefined) {
      this.pageLoad = false;
    }

    this.fetchData(urlParams);

    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'triggerTasksRefresh') {
        this.showTask = null;
        this.fetchData();
      }
    });
  },
  methods: {
    taskDefinitionSelectedCombo: function (values) {
      this.selectedTaskDefinitionsList = values.map((value) => {
        return this.taskQuantities.findIndex(
          (q) => q.taskDefinitionKey === value.value
        );
      });
    },
    taskDefinitionSelectedList: function (values) {
      this.taskDefinitionKeys = values.map(
        (index) => this.taskDefinitionItems[index]
      );
      this.debounceTask = this.debounceLoadTask;
      this.debounceTask();
    },
    projectText: function (cache) {
      if (!cache || !cache.projectId) return '-';
      return (
        cache.projectId + (cache.projectName ? ' - ' + cache.projectName : '')
      );
    },
    fetchData: async function (urlParams = null) {
      this.fetchTasks(urlParams);
      this.fetchQuantity(urlParams);
    },
    fetchTasks: async function (urlParams = null, resetPage = false) {
      this.taskTableLoading = true;

      if (this.pageLoad && !this.isURLSearchParams(urlParams)) {
        return;
      }

      if (resetPage) {
        this.tablePage = 1;
      }

      this.updateUrl();

      if (this.debounceTask) {
        this.debounceTask.cancel();
      }

      if (this.abortController !== null) this.abortController.abort();
      this.abortController = new AbortController();

      const postData = {
        firstResult: this.tableItemsPerPage * (this.tablePage - 1),
        maxResults: this.tableItemsPerPage,
        searchDto: { ...this.searchParams }
      };

      if (this.taskDefinitionKeys.length > 0) {
        postData.searchDto.taskDefinitionKeys = this.taskDefinitionKeys.map(
          (t) => t.value
        );
      } else if (
        this.isURLSearchParams(urlParams) &&
        urlParams.has('taskDefinitionKeys')
      ) {
        postData.searchDto.taskDefinitionKeys = urlParams
          .get('taskDefinitionKeys')
          .split(',');
      }

      if (this.tableSortBy.length > 0) {
        postData.sortOption =
          this.tableSortBy[0] === 'taskDto.created'
            ? 'CREATION_DATE'
            : 'FOLLOWUP_DATE';
        postData.sortOrder = this.tableSortDesc[0] ? 'DESCENDING' : 'ASCENDING';
      }

      return HTTP({
        method: 'POST',
        url: 'taskList/taskList',
        signal: this.abortController.signal,
        data: postData
      })
        .then(({ status, data }) => {
          if (status !== 200) {
            return this.showError('Fehler beim laden der Aufgabenliste');
          }
          this.tasks = data.tasks;
          this.totalTaskCount = data.totalTaskCount;
          if (this.tasks.length === 0 && this.totalTaskCount > 0) {
            this.fetchTasks(null, true);
          }
        })
        .catch((err) => {
          if (!axios.isCancel(err)) throw err;
        })
        .finally(() => {
          this.pageLoad = false;
          this.taskTableLoading = false;
          this.abortController = null;
        });
    },
    updateItemsPerPage: function (oldValue, newValue) {
      this.tableItemsPerPage = newValue;
      this.updateLocalStorage('itemsPerPage', newValue);
      this.updateUrl();
    },
    fetchQuantity: async function (urlParams = null) {
      this.loadQuantities = true;
      HTTP.get('taskList/taskQuantities')
        .then(({ status, data }) => {
          if (status !== 200) {
            return this.showError('Fehler beim laden der Aufgabenliste');
          }
          this.taskQuantities = data.taskCounts.sort(
            (a, b) => b.count - a.count
          );

          if (urlParams && urlParams.has('taskDefinitionKeys')) {
            const keys = urlParams.get('taskDefinitionKeys').split(',');
            // update selected task filter for combobox
            this.taskDefinitionKeys = this.taskDefinitionItems.filter((i) =>
              keys.includes(i.value)
            );
            // update selected task filter for left list
            this.taskDefinitionSelectedCombo(this.taskDefinitionKeys);
            this.updateUrl();
          }
        })
        .finally(() => {
          this.loadQuantities = false;
        });
    },
    openTask: function (task, newTab = false) {
      if (newTab) {
        const routeData = this.$router.resolve({
          name: 'Aufgabe',
          params: {
            id: task.taskDto.id
          }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.showTask = task;
      }
    },
    copyTaskLink: function (taskId) {
      const routeData = this.$router.resolve({
        name: 'Aufgabe',
        params: {
          id: taskId
        }
      });
      const { protocol, host } = window.location;
      navigator.clipboard.writeText(`${protocol}//${host}${routeData.href}`);
    },
    formatDate: function (date) {
      return TimeUtility.intlFormatDateTime(new Date(date));
    },
    formatDateShort: function (date) {
      if (!date) return '';
      return new Date(date).toLocaleString('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    },
    getFormattedDuration: function (date) {
      return this.formatDuration(new Date(date), new Date());
    },
    formatDuration: function (timeA, timeB) {
      const duration = Math.abs(timeA.getTime() - timeB.getTime());
      const milliseconds = 1000;

      const fullMinute = 60 * milliseconds;
      const fullHour = 60 * fullMinute;
      const fullDay = 24 * fullHour;

      let suffix, value;

      if (duration < fullMinute) {
        value = duration / milliseconds;
        suffix = value < 2 ? 'einer Sekunde' : `${Math.floor(value)} Sekunden`;
      } else if (duration > fullDay) {
        value = duration / fullDay;
        suffix = value < 2 ? 'einem Tag' : `${Math.floor(value)} Tagen`;
      } else if (duration > fullHour) {
        value = duration / fullHour;
        suffix = value < 2 ? 'einer Stunde' : `${Math.floor(value)} Stunden`;
      } else {
        value = duration / fullMinute;
        suffix = value < 2 ? 'einer Minute' : `${Math.floor(value)} Minuten`;
      }

      return `Erstellt vor ${suffix}`;
    },
    followUpStyle: function (date) {
      if (!date) return {};
      else if (new Date(date) < new Date(Date.now())) {
        return {
          color: '#eb5252'
        };
      }
    },
    updateLocalStorage: function (key, value) {
      this.$settings.set(`${SETTING_KEY}.${key}`, value);
    },
    showInfo: function (message) {
      this.showMessage(message, 'info');
    },
    showError: function (message) {
      this.showMessage(message, 'error');
    },
    showMessage: function (message, type = 'info') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    updateSelectedTask: function (task) {
      if (this.showTask.taskDto.id === task.id) {
        this.showTask.taskDto = task;
      }
    },
    clearSearch: function () {
      for (const key of Object.keys(this.searchParams)) {
        this.searchParams[key] =
          typeof this.searchParams[key] === 'boolean' ? false : null;
      }
      this.taskDefinitionKeys = [];
      this.projectLookup = null;
      this.fetchTasks();
    },
    refreshSelectedTask: function () {
      this.fetchTask(this.showTask.taskDto.id)
        .then((data) => {
          this.showTask.taskDto = data;
        })
        .catch(() =>
          this.showError('Die Aufgabe konnte nicht aktualisiert werden!')
        );
    },
    fetchTask: function (taskId) {
      return HTTP.get(`/engine/default/task/${taskId}`).then(
        ({ data }) => data
      );
    },
    loadTaskIndex: async function (index) {
      if (index < this.paginationFrom) {
        this.tablePage--;
        await this.fetchTasks();
        this.showTask = this.tasks[this.tableItemsPerPage - 1];
      } else if (index >= this.paginationTo) {
        this.tablePage++;
        await this.fetchTasks();
        this.showTask = this.tasks[0];
      } else {
        const finalIndex = index - (this.paginationFrom - 1);
        this.showTask = this.tasks[finalIndex];
      }
    },
    updateUrl: function () {
      const params = new URLSearchParams();
      if (this.showTask) {
        params.append('task', this.showTask.taskDto.id);
      }
      params.append('page', this.tablePage);
      params.append('itemsPerPage', this.tableItemsPerPage);

      if (this.taskDefinitionKeys.length > 0) {
        params.append(
          'taskDefinitionKeys',
          this.taskDefinitionKeys.map((t) => t.value)
        );
      }

      for (const key of Object.keys(this.searchParams)) {
        const value = this.searchParams[key];
        if (value) {
          params.append(key, value);
        }
      }

      if (this.tableSortBy.length > 0) {
        params.append('sortBy', this.tableSortBy[0]);
        params.append('sortDesc', this.tableSortDesc[0]);
      }

      document.location.hash = params.toString();
    },
    parseURLSearchParams: function () {
      const hash = document.location.hash;
      if (!hash || hash.length < 1) return;
      const params = new URLSearchParams(hash.substring(1));

      if (params.has('page') && this.isNumeric(params.get('page'))) {
        this.tablePage = Number.parseInt(params.get('page'));
      }

      if (
        params.has('itemsPerPage') &&
        this.isNumeric(params.get('itemsPerPage'))
      ) {
        const number = Number.parseInt(params.get('itemsPerPage'));
        if (this.tableItemsPerPageOptions.includes(number)) {
          this.tableItemsPerPage = number;
        }
      }

      if (params.has('sortBy')) {
        const isValid = this.taskTableHeaders
          .map((h) => h.value)
          .includes(params.get('sortBy'));
        this.tableSortBy = isValid ? [params.get('sortBy')] : [];
      } else {
        this.tableSortBy = [];
      }

      if (params.has('sortDesc')) {
        this.tableSortDesc = [params.get('sortDesc').toLowerCase() === 'true'];
      } else {
        this.tableSortDesc = [];
      }

      if (params.has('task')) {
        this.fetchTask(params.get('task'))
          .then((task) => (this.showTask = { taskDto: task }))
          .catch((err) => {
            console.error(err);
            this.showMessage(
              'Die Aufgabe konnte nicht geladen werden',
              'warning'
            );
          });
      }

      for (const key of Object.keys(this.searchParams)) {
        if (params.has(key)) {
          if (typeof this.searchParams[key] === 'boolean') {
            this.searchParams[key] = params.get(key) === 'true';
          } else {
            this.searchParams[key] = params.get(key);
          }
          if (key === 'projectId' || key === 'projectName') {
            this.projectLookup = params.get(key);
          }
        }
      }

      return params;
    },
    isNumeric: function (num) {
      return !isNaN(parseFloat(num)) && isFinite(num);
    },
    isURLSearchParams: function (urlParams) {
      return (
        Object.prototype.toString.call(urlParams) === '[object URLSearchParams]'
      );
    },
    updatePinFilter: function (value) {
      this.pinFilter = value;
      this.updateLocalStorage('pinFilter', value);
    },
    toggleTaskFilterPin: function () {
      this.pinTaskFilter = !this.pinTaskFilter;
      this.updateLocalStorage('pinTaskFilter', this.pinTaskFilter);
    }
  }
};
</script>
<style scoped>
.task-view {
  display: grid;
  grid-template-rows: max-content auto;
  overflow: hidden;
}

.task-definitions > span {
  white-space: break-spaces;
}
</style>
