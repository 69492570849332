<template>
  <v-container>
    <task-content
      v-if="task"
      :data="task"
      @onClose="closeTask"
      @onUpdateEntry="task = $event"
      @refresh="loadTask"
    ></task-content>
    <template v-else-if="historyTask">
      <h4>Die Aufgabe wurde bereits beendet</h4>

      <div class="mt-8 input-row row-spacing">
        <data-display label="Prozess Definition">
          {{ historyTask.processDefinitionKey }}
        </data-display>
        <data-display label="Aufgabe">
          {{ historyTask.name }}
        </data-display>
        <data-display label="Dauer">
          {{ formatDuration(historyTask.duration) }}
        </data-display>
        <data-display label="Startzeit">
          {{ formatDate(historyTask.startTime) }}
        </data-display>
        <data-display label="Endzeit">
          {{ formatDate(historyTask.endTime) }}
        </data-display>
        <data-display label="Assignee">
          {{ historyTask.assignee }}
        </data-display>
        <data-display
          v-if="isAdmin"
          label="Delete Reason"
        >
          {{ historyTask.deleteReason }}
        </data-display>
      </div>
    </template>
    <template v-if="isAdmin && historyProcessVariable">
      <h4 class="my-8">
        Prozess Variablen
      </h4>
      <div class="mt-8 input-row row-spacing">
        <data-display
          v-for="variable of historyProcessVariable"
          :key="variable.id"
          :label="variable.name"
        >
          {{ variable.value }}
        </data-display>
      </div>
    </template>
  </v-container>
</template>
<script>
import { HTTP } from '@/main/httpClient.js';
import TimeUtility from '@/util/TimeUtility.js';
import NotificationObject from '@/main/NotificationObject.js';
import DataDisplay from '@/components/elements/DataDisplay.vue';

import TaskContent from '@/task/TaskContent';

export default {
  name: 'TaskView',
  components: {
    TaskContent,
    DataDisplay
  },
  data: () => ({
    task: null,
    historyTask: null,
    historyProcessVariable: null
  }),
  computed: {
    isAdmin: function () {
      return this.$auth
        .user()
        .userRoles.some((r) => ['camunda-admin', 'LgSpotAdmin'].includes(r));
    }
  },
  mounted: function () {
    document.title = `Aufgabe | ${process.env.VUE_APP_TITLE ?? 'Goetel SPOT'}`;
    this.loadTask();
  },
  methods: {
    loadTask: function () {
      HTTP.get(`/engine/default/task/${this.$route.params.id}`)
        .then(({ data }) => {
          this.task = data;
          document.title = `${data.name}  | ${
            process.env.VUE_APP_TITLE ?? 'Goetel SPOT'
          }`;
        })
        .catch((err) => {
          if (err && err.response && err.response.status) {
            return this.loadTaskHistory();
          }
          this.showError('Die Aufgabe konnte nicht geladen werden!');
        });
    },
    loadTaskHistory: function () {
      HTTP.get(`/engine/default/history/task?taskId=${this.$route.params.id}`)
        .then(({ data }) => {
          if (!data || !(data.length > 0)) return;
          this.historyTask = data[0];
          HTTP.get(
            `/engine/default/history/variable-instance?processInstanceId=${this.historyTask.processInstanceId}`
          ).then(({ data }) => {
            this.historyProcessVariable = data;
          });
        })
        .catch(() => {
          this.showError('Die Aufgabe konnte nicht geladen werden!');
        });
    },
    closeTask: function () {
      this.$router.push({ path: '/task-list' });
    },
    showInfo: function (message) {
      this.showMessage(message, 'info');
    },
    showError: function (message) {
      this.showMessage(message, 'error');
    },
    showMessage: function (message, type = 'info') {
      this.$store.commit(
        'addNotification',
        new NotificationObject(type, message)
      );
    },
    formatDate: function (date) {
      return TimeUtility.intlFormatDateTime(new Date(date));
    },
    formatDuration: function (ms) {
      if (ms < 1000) return `${ms}ms`;

      const seconds = Math.floor(ms / 1000) % 60;
      const minutes = Math.floor(ms / (1000 * 60)) % 60;
      const hours = Math.floor(ms / (1000 * 60 * 60)) % 24;
      const days = Math.floor(ms / (1000 * 60 * 60 * 24));

      const result = [];
      if (days) result.push(`${days}d`);
      if (hours) result.push(`${hours}h`);
      if (minutes) result.push(`${minutes}m`);
      if (seconds) result.push(`${seconds}s`);

      return result.join(' ') || '0s';
    }
  }
};
</script>
